/* Index.css file 
Project: 4inExpo */

@import url("https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;500;700;800&display=swap");
:root {
	--primary: lightblue;
	--secondary: cornflowerblue;
}

* {
	box-sizing: border-box;
}

.slick-dots li.slick-active button:before {
	color: white !important;
}
.slick-dots li button:before {
	color: white !important;
}
/* .hero-background {
  background-image: url("./assets/hero-background.png");
  background-repeat: no-repeat;
} */
/* Firefox */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: "Kaisei Tokumin", serif;
	}
	a {
		@apply text-[#B7D9E2] hover:text-[#B7D9E2];
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		overflow-y: hidden;
	}
	/* table {
		table-layout: fixed;
	}
	td {
		padding: 5px;
		overflow: hidden;
		word-wrap: break-word;
		white-space: nowrap;
	} */
	/* width */
}
@layer components {
	.input {
		@apply appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 mb-4 shadow-xl rounded-md focus:outline-none focus:ring-[#0A2B42] focus:border-[#0A2B42] focus:z-10 sm:text-sm min-w-[200px] lg:min-w-[300px] disabled:bg-gray-200;
	}
	.textarea {
		@apply appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  mb-4 shadow-xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm min-w-[200px] lg:min-w-[300px] disabled:bg-gray-200;
	}
	.overrideInput {
		@apply appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 mb-4 shadow-xl rounded-md focus:outline-none focus:ring-[#0A2B42] focus:border-[#0A2B42] focus:z-10 sm:text-sm min-w-0 lg:min-w-0 disabled:bg-gray-200;
	}
	.overRideTextarea {
		@apply appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  mb-4 shadow-xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm min-w-0 lg:min-w-0 disabled:bg-gray-200;
	}
	/* Heading Sizes */
	.h1 {
		@apply font-bold uppercase  text-3xl md:text-4xl lg:text-6xl;
	}
	.h2 {
		@apply font-bold uppercase text-2xl md:text-3xl lg:text-5xl;
	}
	.h3 {
		@apply font-bold capitalize text-lg md:text-xl lg:text-2xl;
	}
	[data-reach-dialog-content] {
		@apply w-2/3 !important;
	}
	/* Header Colors */
	.header-color {
		@apply bg-[#060D15] text-white;
	}
	/* Home Page Hero Section colors */
	.hero-section-carousel {
		@apply bg-[#F0FEFC];
	}
	.hero-section-color {
		@apply bg-[#0A2B42] text-[#F0FEFC];
	}
	.hero-carousel-dots-color {
		@apply bg-[#F0FEFC];
	}
	.hero-section-button-color {
		@apply bg-[#F0FEFC] text-[#0A2B42];
	}
	/* Home Page about us section colors */
	.about-us-colors {
		@apply bg-[#060D15] text-[#eeeeee];
	}
	.about-us-heading {
		@apply text-[#ffffff];
	}
	.about-us-read-more {
		@apply text-[#ffffff];
	}
	/* Footer Colors */
	.footer-colors {
		@apply bg-[#060D15] text-white;
	}
	/* Why 4inExpo colors */
	.why-heading-colors {
		@apply text-[#0A2B42];
	}
	/* testimonial colors */
	.testimonial-colors {
		@apply bg-[#0A2B42] text-white;
	}
	.testimonial-card-colors {
		@apply bg-white text-[#0A2B42];
	}
	/* contact us colors */
	.contact-us-heading {
		@apply text-[#0A2B42];
	}
	.contact-us-card-colors {
		@apply bg-[#0A2B42] text-white w-[350px] h-[250px];
	}
	.contact-us-card-active-state {
		@apply bg-[#F6D1A7] text-[#0A2B42] w-[350px] h-[250px] scale-110;
	}
	.contact-us-button-colors {
		@apply bg-[#F0FEFC] text-[#0A2B42];
	}

	/* Dashboard area */
	.dashboard-main-colors {
		@apply bg-[#F0FEFC] text-[#0A2B42];
	}
	.dashboard-sidebar-colors {
		@apply bg-[#0A2B42] text-white;
	}
	.alert-colors {
		@apply bg-[#B7D9E2] text-black;
	}
	/* Carousel Override color */
}
@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.hide-scroll::-webkit-scrollbar {
		display: none;
	}

	.hide-scroll {
		/* -ms-overflow-style: none; IE and Edge */
		/* scrollbar-width: none; Firefox */

		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	.hide-scrollbar::-webkit-scrollbar {
		width: 4px;
		overflow-x: hidden;
	}

	.hide-scrollbar::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	.hide-scrollbar::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		outline: 1px solid slategrey;
		border-radius: 6px;
	}
	header .active,
	.dot:hover {
		background-color: #fff !important;
	}
}
